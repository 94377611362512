import React, {useState} from 'react';
// import { convertCompilerOptionsFromJson } from 'typescript';
import './examples.css';

import nettigoImg from './../../assets/nettigo1.png';
import synologyImg from './../../assets/synology-temperature.png';
import arduinoImg from './../../assets/thermistor-arduino-graph.png';
import internetImg from './../../assets/internet-availability1.png';

const examples = [
  { label: 'Weather station',
    language: 'Python',
    code: '# Python\n import requests\n requests.get("https://api.2minlog.com/log?datasetSecret=SEC-12345678-abcd-abcd-abcd-12345678&T=451&humidity=95")'
  },
  { label: 'AWS Lambda',
    language: 'Javascript',
    code: '// Javascript\n fetch("https://api.2minlog.com/log?datasetSecret=SEC-12345678-abcd-abcd-abcd-12345678&debug=123");'
  },
  { label: 'IoT wifi thermometer',
    language: 'C/C++',
    code: '/* C/C++ */\n' + 
    '#include <curl/curl.h>\n'+
    'curl_global_init(CURL_GLOBAL_ALL);\n'+
    'CURL* curl = curl_easy_init();\n'+
    'curl_easy_setopt(curl, CURLOPT_URL, "https://api.2minlog.com/log?datasetSecret=SEC-12345678-abcd-abcd-abcd-12345678&T=451&humidity=42");\n'+
    'curl_easy_perform(curl);\n'+
    'curl_easy_cleanup(curl);\n'+
    'curl_global_cleanup();'
  },
  { label: 'CD/CD pipeline',
    language: 'bash',
    code: '# bash\n curl "https://api.2minlog.com/log?datasetSecret=SEC-12345678-abcd-abcd-abcd-12345678&point=1&status=passed"'
  },
  { label: 'Front end web app',
    language: 'JS/React',
    code: '// JS/React\n fetch("https://api.2minlog.com/log?datasetSecret=SEC-12345678-abcd-abcd-abcd-12345678&button=pressed");'
  },
  { label: 'Dockerized app',
    language: 'Python',
    code: '# Python\n import requests\n requests.get("https://api.2minlog.com/log?datasetSecret=SEC-12345678-abcd-abcd-abcd-12345678&T=451")'
  },
  { label: 'Batch data processing',
    language: 'bash',
    code: '# bash\n curl "https://api.2minlog.com/log?datasetSecret=SEC-12345678-abcd-abcd-abcd-12345678&point=1&status=passed"'
  },
  { label: 'Platform orchestration',
    language: 'Go language',
    code: 'package main\n'+
    'import (\n'+
    '  "io/ioutil"\n'+
    '  "net/http"\n'+
    ')\n'+
    'func main() {\n'+
    '  resp, _ := http.Get("https://api.2minlog.com/log?datasetSecret=SEC-12345678-abcd-abcd-abcd-12345678&T=451&humidity=42")\n'+
    '  ioutil.ReadAll(resp.Body)\n'+
    '  resp.Body.Close()\n'+
    '}'
  },
  { label: 'Android app',
    language: 'Java',
    code: 
'new Thread(() -> {\n'+
'      try {\n'+
'          URL url = new URL("https://api.2minlog.com/log?datasetSecret=SEC-12345678-abcd-abcd-abcd-12345678&lat=40.7128&long=74.0060");\n'+
'          HttpURLConnection urlConnection = (HttpURLConnection) url.openConnection();\n'+
'          InputStream in = new BufferedInputStream(urlConnection.getInputStream());\n'+
'      } catch (Exception e) {\n'+
'          e.printStackTrace();\n'+
'      }\n'+
'  }).start();'
  }
] ;


const examples1 = [
  { label: 'NAS drive temperature',
    hardware: 'SNMP protocol',
    url: synologyImg
  },
  { label: 'Weather station',
    hardware: 'ESP8266 based',
    url: nettigoImg
  },
  { label: 'Adruino thermometer',
    hardware: 'Wifi Arduino',
    url: arduinoImg
  },
  { label: 'Internet up time',
    hardware: 'Regular ping',
    url: internetImg
  }
] ;


const Examples = () => {
  const [code, setCode] = useState('AWS Lambda\n(Javascript)');
  const [url1, setUrl1] = useState('AWS Lambda\n(Javascript)');

  type Props = {
    label: string;
    language: string;
    code: string;
  };

  type Props1 = {
    label: string;
    hardware: string;
    url: string;
  };


  const Exmp = ({label, language, code}: Props) => (
    <div className='log__exmp-card' onMouseEnter={() => {setCode(code);}}>
      <div className='log__exmp-hbar'/>
      <div className='log__exmp-inner1'>
        <p>{label}</p>
        <p>({language})</p>
      </div>
    </div>
    ) ;

  const Exmp1 = ({label, hardware, url}: Props1) => (
    <div className='log__exmp1-card' onMouseEnter={() => {setUrl1(url);}}>
      <div className='log__exmp1-hbar'/>
      <div className='log__exmp1-inner1'>
        <p>{label}</p>
        <p>({hardware})</p>
      </div>
    </div>
    ) ;
  
  return (
  <>
    <div id="examples" className='log__examples'>
      <h1>
        Display your date in awesome graphs!
      </h1>
    </div>
    <div className='log__exmp1'>
      {examples1.map(x => <Exmp1 key={x.label} label={x.label} hardware={x.hardware} url={x.url}/>)}
    </div>
    <div className='log__tutorial'>
      <img alt='Graph example' src={url1}/>
    </div>

    <div id="examples" className='log__examples'>
      <h1>
        Where are people using us?
      </h1>
    </div>
    <div className='log__exmp'>
      {examples.map(x => <Exmp key={x.label} label={x.label} language={x.language} code={x.code}/>)}
    </div>
    <div className='log__tutorial'>
      <p>{code}</p>
    </div>

  </>
);
  }

export default Examples;